
/**
 * StateLess content for strip down content for integration
 */
import { useState } from 'react';
import { Container } from "@mui/material";
import { MDXArticle } from "components";
import { pipComponents } from 'app/topics';
import { ContentContext } from "app/topics/ContentContext";

import type { state } from "app";
import type { ContentProps } from "./StatefulContent";


const key = (contentKey: string, contentId: string) => [contentKey, contentId].join("#")

export const StatelessContent = ({ data: { content } }: ContentProps) => {
  const [contentState, setContentState] = useState<Record<string, any>>({})
  const [goals, setGoals] = useState<state.Goals | null>(null)

  const getContent = (contentId: string) => {
    return contentState[key(content.key, contentId)] || null
  };

  const setContent = (contentId: string, value: any) => {
    setContentState({ ...contentState, [key(content.key, contentId)]: value })
  };

  return (
    <Container>
      <ContentContext.Provider value={{ getContent, setContent, getGoals: () => goals, setGoals }}>
        <MDXArticle components={pipComponents}>{content.body.data.childMdx.body}</MDXArticle>
      </ContentContext.Provider>
    </Container >
  );
}
