import { useEffect, useMemo, ComponentType, ComponentProps } from 'react';
import isEqual from 'lodash/isEqual';
import { Breadcrumbs, Container, Typography, Toolbar, Box } from '@mui/material';
import { Link } from 'components';
import { useAppDispatch, setEntryVisited, updateTopic, useAppSelector } from 'app/store';
import { ensureAuth } from 'app/helpers';
import { NavPage, AppBar } from 'app/layout';
import { InfoPage } from 'app/layout/InfoPage';
import { TopicContent, TopicNav, SearchBox } from 'app/topics';
import { TopicSeqNav } from 'app/topics/TopicSeqNav';
import { useMarkTopicCompletedEffect } from './useMarkTopicCompletedEffect';
import { useFulfillment } from './useFulfillment';

import type { data, state } from 'app';


type TopicContentTemplateProps = {
  topic: Pick<data.Topic, 'key' | 'title' | 'description' | 'contents' | 'cover'>;
  content: Pick<data.Content, 'key' | 'title' | 'body'>;
  userTopic: state.Topic;
};

function TopicContentTemplate(props: TopicContentTemplateProps) {
  const { topic, content, userTopic } = props;
  const dispatch = useAppDispatch();
  const [fulfillment, topicProgress] = useFulfillment(topic, userTopic);

  // update last visited topic entry
  useEffect(() => {
    if (userTopic.lastVisited !== content.key) {
      dispatch(updateTopic({ topicKey: topic.key, data: { lastVisited: content.key } }));
    }
  }, []);

  // update progress if the topic progress is changed
  useEffect(() => {
    if (!isEqual(userTopic.progress, topicProgress)) {
      dispatch(updateTopic({ topicKey: topic.key, data: { progress: topicProgress } }));
    }
  }, [topicProgress]);

  // set visited if the topicEntry has never been visited
  useEffect(() => {
    if (fulfillment[content.key]?.completed === 0) {
      dispatch(setEntryVisited({ topicKey: topic.key, entryKey: content.key }));
    }
  }, [fulfillment]);

  const contents = useMemo(() => {
    return topic.contents.sort((a, b) => a.seq - b.seq);
  }, [topic.contents]);



  return (
    <NavPage
      drawerContent={<TopicNav topic={topic} contents={contents} activeKey={content.key} fulfillment={fulfillment} />}>
      <AppBar title={content.title} actions={<SearchBox />} menuButton />
      <Box sx={{ pb: 4, overflow: 'auto' }} component="main" flex={1}>
        <Toolbar />
        <Container>
          <Breadcrumbs sx={{ marginTop: 1 }} aria-label="breadcrumb">
            <Link color="inherit" href="/">
              Home
            </Link>
            <Link color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography color="textPrimary">
              {topic.title} - <em>{content.title}</em>
            </Typography>
          </Breadcrumbs>
          <TopicContent topic={topic} content={content} userTopic={userTopic} />
          <TopicSeqNav topic={topic} contents={contents} activeKey={content.key} />
        </Container>
      </Box>
    </NavPage>
  );
}

export type ContentProps = {
  data: { topic: data.Topic; content: data.Content };
  pageContext: { topics: data.TopicWithActivityKeys[] };
}

/**
 * Statefull content template with all the user state loaded
 */
export const StatefulContent = ensureAuth(({
  data: { topic, content },
  pageContext: { topics },
}: ContentProps) => {
  const profileData = useAppSelector((state) => state.profile.data!);
  useMarkTopicCompletedEffect(topics, profileData);

  const userTopic = profileData.topics[topic.key];
  if (!userTopic) {
    return <InfoPage>Content was not initialized</InfoPage>;
  }

  if (userTopic.locked) {
    return (
      <InfoPage>
        <strong>Content locked!</strong> The module needs to be unlocked before viewing it.
      </InfoPage>
    );
  }
  return <TopicContentTemplate topic={topic} content={content} userTopic={userTopic} />;
})
